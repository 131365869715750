import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useEagerConnect } from "../hooks/hooks";

// Material UI
import {
	createStyles,
	makeStyles,
	Theme,
	withStyles
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CancelIcon from "@material-ui/icons/Cancel";
import TablePagination from "@material-ui/core/TablePagination";

import TableRow from "@material-ui/core/TableRow";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useIcedTxContext } from "../state/GlobalState";
import {
	findConditionByAddress,
	findActionByAddress,
	stringifyTimestamp,
	findDeprecatedCondition,
	decodeActionPayload,
	getTokenByAddress,
	findDeprecatedAction,
	decodeConditionPayload
} from "../helpers/helpers";

import {
	UPDATE_PAST_TRANSACTIONS,
	COLOURS,
	CANCEL_EXECUTION_CLAIM,
	BOX
} from "../constants/constants";
import { useWeb3React } from "@web3-react/core";
import { useGelatoCore } from "../hooks/hooks";
import {
	ChainIds,
	ActionWhitelistData,
	RelevantInputData
} from "../constants/interfaces";
import { getStatusText } from "../constants/summaryTest";

import { injected } from "../constants/connectors";
import { GelatoButton } from "./Button";
import { Hidden } from "@material-ui/core";
import { ethers } from "ethers";

function desc<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
	order: Order,
	orderBy: K
): (
	a: { [key in K]: number | string },
	b: { [key in K]: number | string }
) => number {
	return order === "desc"
		? (a, b) => desc(a, b, orderBy)
		: (a, b) => -desc(a, b, orderBy);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
}

// interface IcedTxStateTable {
// 	condition: ConditionWhitelistData;
// 	action: ActionWhitelistData;
// }

interface Data {
	id: string;
	condition: string;
	action: string;
	date: number;
	status: string;
	view: number;
	cancel: string;
}

function createData(
	id: string,
	condition: string,
	action: string,
	date: number,
	status: string,
	view: number,
	cancel: string
): Data {
	return { id, condition, action, date, status, view, cancel };
}

const headCells: HeadCell[] = [
	{
		id: "id",
		numeric: true,
		disablePadding: false,
		label: "#"
	},
	{
		id: "condition",
		numeric: true,
		disablePadding: false,
		label: "Condition"
	},
	{ id: "action", numeric: true, disablePadding: false, label: "Action" },
	{ id: "date", numeric: true, disablePadding: false, label: "Created at" },
	{
		id: "status",
		numeric: true,
		disablePadding: false,
		label: "Status"
	},
	{
		id: "view",
		numeric: false,
		disablePadding: false,
		label: "Details"
	},
	{
		id: "cancel",
		numeric: true,
		disablePadding: false,
		label: "Cancel"
	}
];

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>;
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => void;
	onSelectAllClick: (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

// Table Row Styling:
const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			// backgroundColor: theme.palette.common.white,
			background: "none",
			color: "white",
			fontWeight: "bold",
			borderBottom: `1.5px solid ${COLOURS.salmon}`,
			fontSize: 16
			// borderRight: `1.5px solid ${COLOURS.salmon}`
			// ...BOX
		},
		body: {
			fontSize: 16,
			color: "white",
			borderBottom: `1.5px solid ${COLOURS.salmon}`
			// borderRadius: '1px 1px 1px 1px'
		}
	})
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				// backgroundColor: theme.palette.background.default,
				// ...BOX
			}
		}
	})
)(TableRow);

export async function callGraphApi(
	graphName: string,
	account: string,
	skipNum: number
) {
	try {
		const response = await fetch(
			`https://api.thegraph.com/subgraphs/name/gelatodigital/${graphName}`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					query: `{
						users (where: {address:"${account}"}) {
							executionClaims (first: 100, skip: ${skipNum}, orderBy: mintingDate, orderDirection: desc) {
							id
							executionClaimId
							selectedExecutor
							proxyAddress
							condition
							conditionPayload
							action
							actionPayload
							expiryDate
							prepayment
							mintingDate
							executionDate
							executionHash
							status
							conditionGasActionTotalGasMinExecutionGas
						  }
						}
					  }
					  `
				})
			}
		);
		const json = await response.json();
		return json;
	} catch (error) {
		// console.log('No data returned');
	}
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy } = props;

	// const createSortHandler = (property: keyof Data) => (
	// 	event: React.MouseEvent<unknown>
	// ) => {
	// 	onRequestSort(event, property);
	// };

	return (
		<TableHead>
			<StyledTableRow>
				{headCells.map(headCell => (
					<StyledTableCell
						key={headCell.id}
						align={headCell.numeric ? "left" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.label}
					</StyledTableCell>
				))}
			</StyledTableRow>
		</TableHead>
	);
}

const useToolbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			paddingLeft: theme.spacing(2),
			paddingleft: theme.spacing(1)
		},
		paper: {},
		highlight: {
			color: "theme.palette.secondary.main",
			// backgroundColor: lighten(theme.palette.secondary.light, 0.85)
			backgroundColor: "none"
		},
		title: {
			flex: "1 1 100%",
			color: "white",
			fontSize: 20
		}
	})
);

interface EnhancedTableToolbarProps {
	numSelected: number;
	renderCounter: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const classes = useToolbarStyles();
	const web3 = useWeb3React();

	const { numSelected, renderCounter } = props;

	const [currentCount, setCount] = React.useState(0);
	const timer = () => setCount(currentCount + 1);

	useEffect(() => {
		if (currentCount === 3) {
			setCount(0);
		}
		const id = setInterval(timer, 1000);
		return () => clearInterval(id);
	}, [currentCount]);

	return (
		<Toolbar
			style={{
				alignItems: "center",
				flexDirection: "column",
				padding: "0px"
			}}
		>
			{/* <Hidden xsDown>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%'
					}}
				>
					<div
						style={{
							width: '40%'
							// background: 'white',
							// height: '20px'
						}}
					></div>
					<Typography
						className={classes.title}
						variant="h6"
						id="tableTitle"
						style={{ width: '20%' }}
					>
						My Bot Activity
					</Typography>
					<div style={{ width: '40%' }}>
						<GelatoButton>New Instruction</GelatoButton>
					</div>
				</div>
			</Hidden>
			<Hidden smUp> */}
			<Typography className={classes.title} variant="h6" id="tableTitle">
				My Bot Activity
			</Typography>
			{/* </Hidden> */}
			<div
				className={classes.title}
				style={{
					fontSize: "16px",
					marginTop: "8px",
					marginBottom: "8px"
				}}
				id="tableSubTitle"
			>
				{currentCount === 0 && numSelected > 0 && web3.active && "."}
				{currentCount === 1 && numSelected > 0 && web3.active && ".."}
				{currentCount === 2 && numSelected > 0 && web3.active && "..."}
			</div>
			{!web3.active && numSelected === 0 && (
				<div
					className={classes.title}
					style={{
						fontSize: "16px",
						marginTop: "8px",
						marginBottom: "8px"
					}}
					id="tableSubTitle"
				>
					(Please connect to Metamask)
				</div>
			)}
			{numSelected === 0 && web3.active && renderCounter === 5 && (
				<div
					className={classes.title}
					style={{
						fontSize: "16px",
						marginTop: "8px",
						marginBottom: "8px"
					}}
					id="tableSubTitle"
				>
					(No bot instructions found)
				</div>
			)}
		</Toolbar>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%"
			// color: 'white'
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(1),
			overflowX: "auto",
			// minWidth: 750,
			background: "transparent"
			// border: `3px outset ${COLOURS.salmon}`,
			// borderRadius: '2px 2px 2px 2px'
		},
		table: {
			// color: 'white'
		},
		tablePagination: {
			color: "white",
			marginTop: theme.spacing(1)
		},
		visuallyHidden: {
			border: 0,
			clip: "rect(0 0 0 0)",
			height: 1,
			margin: -1,
			overflow: "hidden",
			padding: 0,
			position: "absolute",
			top: 20,
			width: 1,
			color: "pink"
		}
	})
);

export default function EnhancedTable() {
	const {
		dispatch,
		icedTxState: { pastTransactions }
	} = useIcedTxContext();
	const web3 = useWeb3React();

	const networkId = web3.chainId as ChainIds;

	// Router Context
	let history = useHistory();

	const gelatoCore = useGelatoCore();

	/*
	// Eager Connect
	// const triedEager = useEagerConnect();
	const [tried, setTried] = React.useState(false);

	// Stalionator code
	useEffect(() => {
		if (!web3.active) {
			injected.isAuthorized().then((isAuthorized: boolean) => {
				if (isAuthorized) {
					web3.activate(injected, undefined, true).catch(() => {
						setTried(true);
					});
				} else {
					setTried(true);
				}
			});
		}
	}, []); // intentionally only running on mount (make sure it's only mounted once :))

	// if the connection worked, wait until we get confirmation of that to flip the flag
	useEffect(() => {
		if (!tried && web3.active) {
			setTried(true);
		}
	}, [tried, web3.active]);
	*/

	const classes = useStyles();
	const [order, setOrder] = React.useState<Order>("desc");
	const [orderBy, setOrderBy] = React.useState<keyof Data>("date");
	const [selected, setSelected] = React.useState<string[]>([]);
	const [page, setPage] = React.useState(0);
	const [dense /*setDense*/] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	// THE GRAPH API Fetching

	let account: string;
	if (!web3.active) {
		account = "0x0";
	} else {
		account = web3.account as string;
	}

	const rows: Array<Data> = [];

	const [displayedRows, setDisplayedRows] = React.useState(rows);
	const [renderCounter, setRenderCounter] = React.useState(0);

	let graphName: string = "";
	switch (web3.chainId) {
		case 1:
			graphName = "gelato";

			break;
		case 3:
			graphName = "gelato-ropsten";

			break;
		case 4:
			graphName = "gelato-rinkeby";

			break;
		case 42:
			graphName = "gelato-kovan";

			break;
		default:
			graphName = "gelato";
			break;
	}

	async function fetchPastExecutionClaims() {
		try {
			const json = await callGraphApi(graphName, account, 0);
			const executionClaims = json.data.users[0].executionClaims;

			let numberOfExecutionClaims = executionClaims.length;

			let skipNum = 100;
			while (numberOfExecutionClaims >= 100) {
				const json2 = await callGraphApi(graphName, account, skipNum);

				const executionClaims2 = json2.data.users[0].executionClaims;
				executionClaims2.forEach((executionClaim: any) => {
					executionClaims.push(executionClaim);
				});
				numberOfExecutionClaims = executionClaims2.length;
				skipNum += 100;
			}

			let newRows: Array<Data> = [];

			// Change global state
			dispatch({
				type: UPDATE_PAST_TRANSACTIONS,
				pastTransactions: executionClaims
			});

			let counter = 0;
			for (let index = 0; index < executionClaims.length; index++) {
				const executionClaim = executionClaims[index];
				let condition = findConditionByAddress(
					executionClaim.condition,
					web3.chainId as ChainIds
				);

				if (condition.id === 0) {
					condition = findDeprecatedCondition(
						executionClaim.condition,
						web3.chainId as ChainIds
					);
				}

				let action = findActionByAddress(
					executionClaim.action,
					web3.chainId as ChainIds
				);

				if (action.id === 0) {
					action = findDeprecatedAction(
						executionClaim.action,
						web3.chainId as ChainIds
					);
				}

				let statusString = getStatusText(executionClaim.status);

				if (statusString === "open") {
					const actionConditionsOk = await callActionConditionsOk(
						executionClaim
					);

					// console.log(actionConditionsOk);
					const conditionOk = await checkConditionStatus(executionClaim);
					// console.log(conditionOk);
					if (conditionOk && actionConditionsOk !== "ok")
						statusString =
							"condition fulfilled, but action not executable - please contact us";
				}

				// @DEV USE THIS Decoding when the view button is being clicked
				// console.log(condition);
				// console.log(action);
				const newData = createData(
					counter.toString(),
					condition.title,
					action.title,
					executionClaim.mintingDate,
					statusString,
					index,
					"CANCEL"
				);
				newRows.push(newData);
				counter = counter + 1;
			}
			// executionClaims.forEach((executionClaim: any, index: any) => {
			// 	// With address, find condition and action

			// 	// Date when the claim was created
			// });
			setDisplayedRows(newRows);
		} catch (err) {
			// console.log(err);
			// console.log('Could not fetch past execution claims');

			// Do UseEffect 5 times, if it API request still fails, stop
			if (renderCounter < 5) setRenderCounter(renderCounter + 1);
		}
	}

	// Create Fake canExecute Func, which
	// 1. Decodes Condition params
	// 2. instantiates Condition Contract
	// 3. Calls condition function with user inputs
	// 4. Checks if these return true
	const checkConditionStatus = async (executionClaim: any) => {
		let condition = findConditionByAddress(executionClaim.condition, networkId);

		if (condition.id === 0) {
			condition = findDeprecatedCondition(
				executionClaim.condition,
				web3.chainId as ChainIds
			);
		}

		const signer = web3.library.getSigner();

		const conditionInputs = decodeConditionPayload(
			executionClaim.conditionPayload,
			condition.params
		);
		// console.log(actionCo	nditionsOkAbi);
		try {
			const conditionContract = new ethers.Contract(
				condition.address[networkId],
				[condition.abi],
				signer
			);
			try {
				const conditionOk = await conditionContract.reached(...conditionInputs);
				let result = false;
				if (conditionOk[0] !== undefined) result = conditionOk[0] as boolean;

				return result;
			} catch (error) {
				// console.log(error);
				return false;
			}
		} catch (error) {
			// console.log('coant find condition');
			// console.log(error);
			return false;
		}
	};

	// useEffect(() => {
	// 	if (web3.active) {
	// 		dummyUniswapTX();
	// 	}
	// }, [web3.active]);

	// const dummyUniswapTX = async () => {
	// 	const erc20Abi =
	// 		'function approve(address spender, uint256 amount) external returns (bool)';

	// 	const signer = web3.library.getSigner();
	// 	// console.log(action.address[networkId]);
	// 	// console.log(actionConditionsOkAbi);
	// 	const ercContract = new ethers.Contract(
	// 		'0x7d669a64deb8a4a51eea755bb0e19fd39ce25ae9',
	// 		[erc20Abi],
	// 		signer
	// 	);

	// 	const sellAmount = ethers.utils.parseEther('10');

	// 	try {
	// 		const actionConditionsOkValue = await ercContract.approve(
	// 			'0xC9883D347C200d9CBA014389220C4df45648F03a',
	// 			sellAmount
	// 		);
	// 		console.log(actionConditionsOkValue);
	// 		// console.log(actionConditionsOkValue);
	// 	} catch (error) {
	// 		console.log(error);
	// 		return `Error in actionConditionsOkCheck`;
	// 	}

	// 	const uniswapAbi =
	// 		'function tokenToEthSwapInput(uint256 tokensSold, uint256 minEth, uint256 deadline) returns (uint256 ethBought)';

	// 	// console.log(action.address[networkId]);
	// 	// console.log(actionConditionsOkAbi);
	// 	const uniswapContract = new ethers.Contract(
	// 		'0xC9883D347C200d9CBA014389220C4df45648F03a',
	// 		[uniswapAbi],
	// 		signer
	// 	);

	// 	try {
	// 		const worked = await uniswapContract.tokenToEthSwapInput(
	// 			sellAmount,
	// 			1,
	// 			1609463522
	// 		);
	// 		console.log(worked);
	// 		// console.log(actionConditionsOkValue);
	// 	} catch (error) {
	// 		return `Error in actionConditionsOkCheck`;
	// 		console.log(error);
	// 	}
	// };

	const callActionConditionsOk = async (executionClaim: any) => {
		let action = findActionByAddress(executionClaim.action, networkId);

		if (action.id === 0) {
			action = findDeprecatedAction(
				executionClaim.action,
				web3.chainId as ChainIds
			);
		}
		// TEST => CALL CAN EXECUTE
		// 'function action(address _user, address _userProxy, address _src, uint256 _srcAmount, address _beneficiary)'
		const regex = /action/;
		// const actionConditionsOkAbi =
		// 	action.abi.replace(regex, 'actionConditionsCheck') +
		// 	' view returns (string)';

		const actionConditionsOkAbi =
			"function actionConditionsCheck(bytes _actionPayloadWithSelector) view returns (string)";

		const signer = web3.library.getSigner();
		// console.log(action.address[networkId]);
		// console.log(actionConditionsOkAbi);

		try {
			const actionContract = new ethers.Contract(
				action.address[networkId],
				[actionConditionsOkAbi],
				signer
			);

			try {
				const actionConditionsOkValue = await actionContract.actionConditionsCheck(
					executionClaim.actionPayload
				);
				return actionConditionsOkValue;
				// console.log(actionConditionsOkValue);
			} catch (error) {
				// console.log(error);
				return `Error in actionConditionsOkCheck`;
			}
		} catch (error) {
			// console.log('cannot find contract');
		}

		// console.log(account);
		// console.log(executionClaim.proxyAddress);
		// console.log(...actionInputs);

		// Check MarketLoquidity for pToken
		/* function marketLiquidityForToken()
			public
			view
			returns (uint256);
		*/

		// const actionInputs = decodeActionPayload(
		// 	executionClaim.actionPayload,
		// 	action.params
		// );

		// const pTokenAddress = actionInputs[2];
		// const pTokenAbi = [
		// 	'function marketLiquidityForLoan() view returns (uint256)',
		// 	'function loanTokenDecimals() view returns (uint256)',
		// 	'function loanTokenAddress() view returns (address)',
		// 	'function tradeTokenAddress() view returns (address)',
		// 	'function getMaxDepositAmount() view returns (uint256)'
		// ];

		// const pTokenContract = new ethers.Contract(
		// 	pTokenAddress,
		// 	pTokenAbi,
		// 	signer
		// );

		// try {
		// 	const pTokenReturnValue = await pTokenContract.marketLiquidityForLoan();

		// 	const pTokenLoanTokenDecimals = await pTokenContract.loanTokenDecimals();

		// 	const pTokenLoanTokenAddress = await pTokenContract.loanTokenAddress();

		// 	const pTokenTradeTokenAddress = await pTokenContract.tradeTokenAddress();

		// 	const maxDepositAmount = await pTokenContract.getMaxDepositAmount();

		// 	const loanToken = getTokenByAddress(
		// 		pTokenLoanTokenAddress,
		// 		networkId,
		// 		RelevantInputData.kyberTokenList
		// 	);
		// 	const pToken = getTokenByAddress(
		// 		pTokenAddress,
		// 		networkId,
		// 		RelevantInputData.fulcrumTokenList
		// 	);

		// 	const tradeToken = getTokenByAddress(
		// 		pTokenTradeTokenAddress,
		// 		networkId,
		// 		RelevantInputData.all
		// 	);
		// 	// console.log(`LoanToken:`);
		// 	// console.log(loanToken.name);
		// 	// console.log(`pToken:`);
		// 	// console.log(pToken.name);
		// 	// console.log(`TradeToken:`);
		// 	// console.log(tradeToken.name);
		// 	// console.log('Loan Token Decimals');
		// 	// console.log(pTokenLoanTokenDecimals.toString());
		// 	// console.log('Max Loan Value');
		// 	// console.log(pTokenReturnValue.toString());
		// 	// console.log('Max Deposit Amount');
		// 	// console.log(maxDepositAmount.toString());

		// 	// console.log(`################`);
		// 	// console.log(`################`);
		// 	// console.log(`################`);
		// } catch (error) {
		// 	console.log(error);
		// }
	};

	useEffect(() => {
		let requestCancelled = false;
		if (!requestCancelled) {
			fetchPastExecutionClaims();
		}

		const intervalId = setInterval(() => {
			fetchPastExecutionClaims();
		}, 20000);

		// this will clear Timeout when component unmont like in willComponentUnmount

		return () => {
			clearInterval(intervalId);
			requestCancelled = true;
		};
		// Clean up function
	}, [renderCounter, web3.active, web3.account]);

	// Cancel ExecutionClaim

	const cancelExecutionClaim = async (executionClaimId: string) => {
		// Update Tx State
		// Update pastTransactionId
		// Open MOdal
		dispatch({
			type: CANCEL_EXECUTION_CLAIM,
			pastTransactionId: executionClaimId
		});
	};

	const showDetails = (event: React.MouseEvent<unknown>, row: Data) => {
		history.push(`/dashboard/${row.view}`);
	};

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isDesc = orderBy === property && order === "desc";
		setOrder(isDesc ? "asc" : "desc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			// @DEV CHANGED NAME TO ID
			const newSelecteds = displayedRows.map(n => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, displayedRows.length - page * rowsPerPage);

	return (
		<React.Fragment>
			<div className={classes.root}>
				{/* <Button color={'secondary'} onClick={fetchPastExecutionClaims}>
					Fetch
				</Button> */}
				<Paper className={classes.paper}>
					<EnhancedTableToolbar
						numSelected={displayedRows.length}
						renderCounter={renderCounter}
					/>

					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={dense ? "small" : "medium"}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={displayedRows.length}
						/>
						<TableBody>
							{stableSort(displayedRows, getSorting(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									// const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<StyledTableRow
											hover
											onClick={event => handleClick(event, row.id)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
										>
											<StyledTableCell align="left">
												{parseInt(row.id) + 1}
											</StyledTableCell>
											<StyledTableCell align="left">
												{row.condition}
											</StyledTableCell>
											<StyledTableCell align="left">
												{row.action}
											</StyledTableCell>
											<StyledTableCell align="left">
												{stringifyTimestamp(row.date.toString())}
											</StyledTableCell>
											<StyledTableCell align="left">
												{row.status}
											</StyledTableCell>
											<StyledTableCell align="left">
												<div
													onClick={e => showDetails(e, row)}
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														// marginRight: '8px',
														cursor: "pointer"
													}}
												>
													<VisibilityIcon
														// color="primary"
														fontSize={"small"}
														color="primary"
													></VisibilityIcon>
												</div>
											</StyledTableCell>
											<StyledTableCell align="left">
												{/* {row.cancel} */}
												{row.status !== "cancelled" &&
													row.status !== "expired" &&
													row.status !== "successfully executed" &&
													row.status !==
														"failed to execute - please contact us" && (
														<div
															onClick={() => cancelExecutionClaim(row.id)}
															style={{
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
																cursor: "pointer"
															}}
														>
															<CancelIcon
																color="primary"
																fontSize={"small"}
															></CancelIcon>
														</div>
													)}
											</StyledTableCell>
										</StyledTableRow>
									);
								})}
							{emptyRows > 0 && (
								<StyledTableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows
									}}
								>
									<StyledTableCell colSpan={headCells.length} />
								</StyledTableRow>
							)}
						</TableBody>
					</Table>
					<TablePagination
						className={classes.tablePagination}
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={displayedRows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>
		</React.Fragment>
	);
}
