import React from 'react';

import AppSelection from '../components/AppSelection';

export default function Configurator() {
	return (
		<React.Fragment>
			<AppSelection />
		</React.Fragment>
	);
}
