import { RelevantInputData } from './interfaces';
import { ETH } from './constants';

const BAT = {
	address: {
		1: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
		3: '0x0',
		4: '0x0',
		42: '0x0'
	},
	symbol: 'BAT',
	name: 'Basic Attention Token',
	decimals: 18,
	max: '1018'
};

export const LINK = {
	address: {
		1: '0x514910771af9ca656af840dff83e8264ecf986ca',
		3: '0x0',
		4: '0x0',
		42: '0x0'
	},
	symbol: 'LINK',
	name: 'ChainLink',
	decimals: 18,
	max: '105'
};

export const KNC = {
	address: {
		1: '0xdd974d5c2e2928dea5f71b9825b8b646686bd200',
		3: '0x0',
		4: '0x0',
		42: '0xad67cB4d63C9da94AcA37fDF2761AaDF780ff4a2'
	},
	symbol: 'KNC',
	name: 'Kyber Network',
	decimals: 18,
	max: '1000'
};

// export enum TokenList {
//     KyberTokenList = 0
// }

export const WBTC = {
	address: {
		1: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
		3: '0x0',
		4: '0x0',
		42: '0x0'
	},
	symbol: 'WBTC',
	name: 'Wrapped Bitcoin',
	decimals: 8,
	max: '0.25'
};

export const ZRX = {
	name: '0x Protocol Token',
	symbol: 'ZRX',
	decimals: 18,
	address: {
		1: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
		3: '0x0',
		4: '0x0',
		42: '0x0'
	},
	max: '993'
};

export const KYBER_TOKEN_LIST = [
	{ ...BAT },
	{
		address: {
			1: '0x6b175474e89094c44da98b954eedeac495271d0f',
			3: '0x0',
			4: '0x0',
			42: '0xC4375B7De8af5a38a93548eb8453a498222C4fF2'
		},
		symbol: 'DAI',
		name: 'DAI Stablecoin',
		decimals: 18,
		max: '275'
	},
	{
		address: {
			1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
			3: '0x0',
			4: '0x0',
			42: '0xd0A1E359811322d97991E03f863a0C30C2cF029C'
		},
		symbol: 'WETH',
		name: 'Wrapped Ether',
		decimals: 18,
		max: '1.53'
	},
	{ ...WBTC },
	// {
	// 	address: {
	// 		1: '0x6810e776880c02933d47db1b9fc05908e5386b96',
	// 		3: '0x0',
	// 		4: '0x0',
	// 		42: '0x0'
	// 	},
	// 	symbol: 'GNO',
	// 	name: 'Gnosis',
	// 	decimals: 18,
	// 	max: '21'
	// },
	// {
	// 	address: {
	// 		1: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
	// 		3: '0x0',
	// 		4: '0x0',
	// 		42: '0xcb78b457c1F79a06091EAe744aA81dc75Ecb1183'
	// 	},
	// 	symbol: 'MANA',
	// 	name: 'Decentraland',
	// 	decimals: 18
	// },

	{
		...KNC
	},

	{
		...LINK
	},
	{
		address: {
			1: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
			3: '0x0',
			4: '0x0',
			42: '0xAaF64BFCC32d0F15873a02163e7E500671a4ffcD'
		},
		symbol: 'MKR',
		name: 'Maker',
		decimals: 18,
		max: '0.55'
	},
	{
		address: {
			1: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'SAI',
		name: 'SAI Stablecoin',
		decimals: 18,
		max: '275'
	},
	//Harcoded: 0xc011a72400e58ecd99ee497cf89e3775d4bd732f
	// {
	// 	address: {
	// 		1: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
	// 		3: '0x0',
	// 		4: '0x0',
	// 		42: '0x0'
	// 	},
	// 	symbol: 'SNX',
	// 	name: 'Synthetix Network Token',
	// 	decimals: 18,
	// 	max: '240'
	// },

	// {
	// 	address: {
	// 		1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
	// 		3: '0x0',
	// 		4: '0x0',
	// 		42: '0x0'
	// 	},
	// 	symbol: 'USDT',
	// 	name: 'Tether',
	// 	decimals: 6,
	// 	max: '275'
	// },
	{
		address: {
			1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'USDC',
		name: 'USD Coin',
		decimals: 6,
		max: '275'
	},
	{ ...ZRX }
];

export const KYBER_TOKEN_LIST_WITH_ETH = [{ ...ETH }, ...KYBER_TOKEN_LIST];

export const FULCRUM_LEVERAGE_TOKEN_LIST = [
	// ETHER
	{
		address: {
			1: '0xd80e558027ee753a0b95757dc3521d0326f13da2',
			3: '0x0',
			4: '0x0',
			42: '0x934b43143e984052961EB46f5bDE633F33bCDB80'
		},
		symbol: 'dLETH2x',
		name: '2x Long ETH',
		decimals: 18,
		max: '0.0014'
	},
	{
		address: {
			1: '0x1370b716575bd7d5aee14128e231a779198e5397',
			3: '0x0',
			4: '0x0',
			42: '0x0015Cfd9722B43ac277f37887df14a00109fc689'
		},
		symbol: 'dLETH3x',
		name: '3x Long ETH',
		decimals: 18,
		max: '0.0013'
	},
	{
		address: {
			1: '0xf2ad1ee9671f63df7c8f8daa822da1e6fc08b80d',
			3: '0x0',
			4: '0x0',
			42: '0x0E5f87BDcD6285F930b6bbcC3E21CA9d985e12fE'
		},
		symbol: 'dLETH4x',
		name: '4x Long ETH',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0x3733edd72261a770f57070d93e5bfd1c9f6ae9f4',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLETH5x',
		name: '5x Long ETH',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0xd2a1d068baac0b06a8e2b1dc924a43d81a6da325',
			3: '0x0',
			4: '0x0',
			42: '0xD4Fd1467c867808dc7B393dBc863f34783F37d3E'
		},
		symbol: 'dsETH',
		name: 'Short ETH',
		decimals: 18,
		max: '150'
	},
	{
		address: {
			1: '0x8fa1a491f55d93bd40ff023956261f2fb5047297',
			3: '0x0',
			4: '0x0',
			42: '0x2EBfbCf2d67867a05BCAC0FbCA54019163253988'
		},
		symbol: 'dsETH2x',
		name: '2x Short ETH',
		decimals: 18,
		max: '0.7'
	},
	{
		address: {
			1: '0x0428488582472a47d7a20be969fdfdfb3ba1f7cb',
			3: '0x0',
			4: '0x0',
			42: '0xB56EA362eA9B1D030213A47eAA452dFfd84CB5a2'
		},
		symbol: 'dsETH3x',
		name: '3x Short ETH',
		decimals: 18,
		max: '0.34'
	},
	{
		address: {
			1: '0x46bb4576993f50302bb0d5f7440aeffbabfdbb78',
			3: '0x0',
			4: '0x0',
			42: '0x9486ac55ed81758787fcdda98e6Ce35b01CDBE72'
		},
		symbol: 'dsETH4x',
		name: '4x Short ETH',
		decimals: 18,
		max: '17.3'
	},
	{
		address: {
			1: '0xa532a99e877c3ff152f1c8e1281b8b45dedb9f2c',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsETH5x',
		name: '5x Short ETH',
		decimals: 18,
		max: '17.3'
	},

	// WRAPPED BTC
	{
		address: {
			1: '0xE5Bd12178910c928B69CD090b18D4B981f1150dc',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLWBTC2x',
		name: '2x Long WBTC',
		decimals: 18,
		max: '0.0000201'
	},
	{
		address: {
			1: '0x038e1b56b615ff3dd20e0bd4c7e91c7ee07d3508',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLWBTC3x',
		name: '3x Long WBTC',
		decimals: 18,
		max: '0.0000168'
	},
	{
		address: {
			1: '0xdbc54c60a4dde6f95de3c78af59f9ff77e26afd2',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLWBTC4x',
		name: '4x Long WBTC',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0xc3212f2cc694b6f24afdbef66ea44512345b7c7c',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLWBTC5x',
		name: '5x Long WBTC',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0x5590b05495bad72365d4afaa3ed5fba5d8d67af2',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsWBTC',
		name: 'Short WBTC',
		decimals: 18,
		max: '150'
	},
	{
		address: {
			1: '0xe63d611b76ee1ad92df5153f3e573f7453ca0901',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsWBTC2x',
		name: '2x Short WBTC',
		decimals: 18,
		max: '0.7'
	},
	{
		address: {
			1: '0x7dd474dce035debf073dcb9c188584d761b1d024',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsWBTC3x',
		name: '3x Short WBTC',
		decimals: 18,
		max: '0.34'
	},
	{
		address: {
			1: '0x11babe4a1dee350ba5b5ad902f1d2c9822a0a98b',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsWBTC4x',
		name: '4x Short WBTC',
		decimals: 18,
		max: '17.3'
	},
	{
		address: {
			1: '0x92ecfd712c47aaf2dc600e3dcee20fa500dc996a',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsWBTC5x',
		name: '5x Short WBTC',
		decimals: 18,
		max: '17.3'
	},
	// LINK
	{
		address: {
			1: '0x91f46dd6ee5f3f8ce83287897938f7858f5761e4',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLLINK2x',
		name: '2x Long LINK',
		decimals: 18,
		max: '0.0014'
	},
	{
		address: {
			1: '0xc0f2ad96e2e946b507f903bb5ef69a08b1bdc766',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLLINK3x',
		name: '3x Long LINK',
		decimals: 18,
		max: '0.0013'
	},
	{
		address: {
			1: '0xcc16745a1773dd95ab9ed98599b8d9b835e42e25',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLLINK4x',
		name: '4x Long LINK',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0x3bf13d76a5d625fc85486f73328926a64f226acc',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsLINK',
		name: 'Short LINK',
		decimals: 18,
		max: '150'
	},
	{
		address: {
			1: '0x7d7ab1db2c8766d98465a392f374eb97361289ef',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsLINK2x',
		name: '2x Short LINK',
		decimals: 18,
		max: '0.7'
	},
	{
		address: {
			1: '0x4a1db1005cbc5684aa51e7a4eee06db0298e70f6',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsLINK3x',
		name: '3x Short LINK',
		decimals: 18,
		max: '0.34'
	},
	{
		address: {
			1: '0x1f8bdad5f30effdf14c8da175349c7207bb370f8',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsLINK4x',
		name: '4x Short LINK',
		decimals: 18,
		max: '17.3'
	},
	// Kyber Network
	{
		address: {
			1: '0x123f2b75b9a7a9da8dc483976bfd81eff9f0ea8d',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLKNC2x',
		name: '2x Long KNC',
		decimals: 18,
		max: '0.0014'
	},
	{
		address: {
			1: '0x9cf9eb6650fa6cd1d8f6f7949ab4dc24589d45b8',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLKNC3x',
		name: '3x Long KNC',
		decimals: 18,
		max: '0.0013'
	},
	{
		address: {
			1: '0x8846c5cad73ff19007367c1366b85e75c1885165',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLKNC4x',
		name: '4x Long KNC',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0x214faee84e66b72241acc112df605873e544dbd5',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLKNC5x',
		name: '5x Long KNC',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0xc8c8205297b0908b9d0b16aa95c8d0108d615d25',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsKNC',
		name: 'Short KNC',
		decimals: 18,
		max: '150'
	},
	{
		address: {
			1: '0x1c3d23828c886e6138b725c4bce41f90ab19f10e',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsKNC2x',
		name: '2x Short KNC',
		decimals: 18,
		max: '0.7'
	},
	{
		address: {
			1: '0x3c961a0a2ec87247897a07e66d9d9967a317abde',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsKNC3x',
		name: '3x Short KNC',
		decimals: 18,
		max: '0.34'
	},
	{
		address: {
			1: '0xaa46973900eb6b99ce0bd6e5f804217dbd192dfb',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsKNC4x',
		name: '4x Short KNC',
		decimals: 18,
		max: '17.3'
	},
	{
		address: {
			1: '0xf64218f3df4f238d797acbdf9849895e0b970ea1',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsKNC5x',
		name: '5x Short KNC',
		decimals: 18,
		max: '17.3'
	},
	// 0x (ZRX)
	{
		address: {
			1: '0x0dd55d33b46151b9d2427a23ec6a9986fdd74745',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLZRX2x',
		name: '2x Long ZRX',
		decimals: 18,
		max: '0.0014'
	},
	{
		address: {
			1: '0x79c6ed6d205dd41f00f3561a1d684c1f39152a20',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLZRX3x',
		name: '3x Long ZRX',
		decimals: 18,
		max: '0.0013'
	},
	{
		address: {
			1: '0xb9d90d2292ecaaeb616a7c709ec6d24f9fd5e286',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLZRX4x',
		name: '4x Long ZRX',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0xa2213efe002bc00460769a3a2f40f7d1152999c4',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dLZRX5x',
		name: '5x Long ZRX',
		decimals: 18,
		max: '0.00138'
	},
	{
		address: {
			1: '0xb40e02f0e11081140a062f06b6692180c0199dd9',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsZRX',
		name: 'Short ZRX',
		decimals: 18,
		max: '150'
	},
	{
		address: {
			1: '0x8b9b98feec18b390cb82c33a55a78e81151cc6ed',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsZRX2x',
		name: '2x Short ZRX',
		decimals: 18,
		max: '0.7'
	},
	{
		address: {
			1: '0x889badfa1afafb0e783b9f91626d71aeb1a3b113',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsZRX3x',
		name: '3x Short ZRX',
		decimals: 18,
		max: '0.34'
	},
	{
		address: {
			1: '0xd2b6c055b915cba38490b586a9e22aafcc3a15cc',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsZRX4x',
		name: '4x Short ZRX',
		decimals: 18,
		max: '17.3'
	},
	{
		address: {
			1: '0x508b1cd933736384114f2f66264f0f513558d8d9',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		symbol: 'dsZRX5x',
		name: '5x Short ZRX',
		decimals: 18,
		max: '17.3'
	}
];

//################################################################### UNISWAP

export const UNISWAP_TOKEN_LIST = [
	{
		name: '0xBitcoin Token',
		symbol: '0xBTC',
		decimals: 8,
		address: {
			1: '0xB6eD7644C69416d67B522e20bC294A9a9B405B31',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Aave Interest bearing DAI',
		symbol: 'aDAI',
		decimals: 18,
		address: {
			1: '0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Amon',
		symbol: 'AMN',
		decimals: 18,
		address: {
			1: '0x737F98AC8cA59f2C68aD658E3C3d8C8963E40a4c',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Ampleforth',
		symbol: 'AMPL',
		decimals: 9,
		address: {
			1: '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Aragon Network Token',
		symbol: 'ANT',
		decimals: 18,
		address: {
			1: '0x960b236A07cf122663c4303350609A66A7B288C0',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Basic Attention Token',
		symbol: 'BAT',
		decimals: 18,
		address: {
			1: '0x0D8775F648430679A709E98d2b0Cb6250d2887EF',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Bloom Token',
		symbol: 'BLT',
		decimals: 18,
		address: {
			1: '0x107c4504cd79C5d2696Ea0030a8dD4e92601B82e',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Bancor Network Token',
		symbol: 'BNT',
		decimals: 18,
		address: {
			1: '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Crypto20',
		symbol: 'C20',
		decimals: 18,
		address: {
			1: '0x26E75307Fc0C021472fEb8F727839531F112f317',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'CelerToken',
		symbol: 'CELR',
		decimals: 18,
		address: {
			1: '0x4F9254C83EB525f9FCf346490bbb3ed28a81C667',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Compound Dai',
		symbol: 'cSAI',
		decimals: 8,
		address: {
			1: '0xF5DCe57282A584D2746FaF1593d3121Fcac444dC',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Compound Dai',
		symbol: 'cDAI',
		decimals: 8,
		address: {
			1: '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Chai',
		symbol: 'CHAI',
		decimals: 18,
		address: {
			1: '0x06AF07097C9Eeb7fD685c692751D5C66dB49c215',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Civic',
		symbol: 'CVC',
		decimals: 8,
		address: {
			1: '0x41e5560054824eA6B0732E656E3Ad64E20e94E45',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Dai Stablecoin v1.0 (SAI)',
		symbol: 'SAI',
		decimals: 18,
		address: {
			1: '0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Dai Stablecoin',
		symbol: 'DAI',
		decimals: 18,
		address: {
			1: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
			3: '0x0',
			4: '0x0',
			42: '0xC4375B7De8af5a38a93548eb8453a498222C4fF2'
		},
		max: '999'
	},
	{
		name: 'Streamr DATAcoin',
		symbol: 'DATA',
		decimals: 18,
		address: {
			1: '0x0Cf0Ee63788A0849fE5297F3407f701E122cC023',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'DigixDAO',
		symbol: 'DGD',
		decimals: 9,
		address: {
			1: '0xE0B7927c4aF23765Cb51314A0E0521A9645F0E2A',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Digix Gold Token',
		symbol: 'DGX',
		decimals: 9,
		address: {
			1: '0x4f3AfEC4E5a3F2A6a1A411DEF7D7dFe50eE057bF',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Decentralized Insurance Protocol',
		symbol: 'DIP',
		decimals: 18,
		address: {
			1: '0xc719d010B63E5bbF2C0551872CD5316ED26AcD83',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Donut',
		symbol: 'DONUT',
		decimals: 18,
		address: {
			1: '0xC0F9bD5Fa5698B6505F643900FFA515Ea5dF54A9',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Enjin Coin',
		symbol: 'ENJ',
		decimals: 18,
		address: {
			1: '0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'SAINT FAME: Genesis Shirt',
		symbol: 'FAME',
		decimals: 18,
		address: {
			1: '0x06f65b8CfCb13a9FE37d836fE9708dA38Ecb29B2',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'FOAM Token',
		symbol: 'FOAM',
		decimals: 18,
		address: {
			1: '0x4946Fcea7C692606e8908002e55A582af44AC121',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'FunFair',
		symbol: 'FUN',
		decimals: 8,
		address: {
			1: '0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'DAOstack',
		symbol: 'GEN',
		decimals: 18,
		address: {
			1: '0x543Ff227F64Aa17eA132Bf9886cAb5DB55DCAddf',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Gnosis Token',
		symbol: 'GNO',
		decimals: 18,
		address: {
			1: '0x6810e776880C02933D47DB1b9fc05908e5386b96',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'GRID Token',
		symbol: 'GRID',
		decimals: 12,
		address: {
			1: '0x12B19D3e2ccc14Da04FAe33e63652ce469b3F2FD',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Gastoken.io',
		symbol: 'GST2',
		decimals: 2,
		address: {
			1: '0x0000000000b3F879cb30FE243b4Dfee438691c04',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	// {
	// 	name: 'Fulcrum DAI iToken ',
	// 	symbol: 'iDAI',
	// 	decimals: 18,
	// 	address: {
	// 		1: '0x493C57C4763932315A328269E1ADaD09653B9081',
	// 		3: '0x0',
	// 		4: '0x0',
	// 		42: '0x0'
	// 	},
	// 	max: '999'
	// },
	// {
	// 	name: 'Fulcrum SAI iToken ',
	// 	symbol: 'iSAI',
	// 	decimals: 18,
	// 	address: {
	// 		1: '0x14094949152EDDBFcd073717200DA82fEd8dC960',
	// 		3: '0x0',
	// 		4: '0x0',
	// 		42: '0x0'
	// 	},
	// 	max: '999'
	// },
	{
		name: 'The Tokenized Bitcoin',
		symbol: 'imBTC',
		decimals: 8,
		address: {
			1: '0x3212b29E33587A00FB1C83346f5dBFA69A458923',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'IoTeX Network',
		symbol: 'IOTX',
		decimals: 18,
		address: {
			1: '0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Kin',
		symbol: 'KIN',
		decimals: 18,
		address: {
			1: '0x818Fc6C2Ec5986bc6E2CBf00939d90556aB12ce5',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Kyber Network Crystal',
		symbol: 'KNC',
		decimals: 18,
		address: {
			1: '0xdd974D5C2e2928deA5F71b9825b8b646686BD200',
			3: '0x0',
			4: '0x0',
			42: '0xad67cB4d63C9da94AcA37fDF2761AaDF780ff4a2'
		},
		max: '999'
	},
	{
		name: 'ChainLink Token',
		symbol: 'LINK',
		decimals: 18,
		address: {
			1: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'HoloToken',
		symbol: 'HOT',
		decimals: 18,
		address: {
			1: '0x6c6EE5e31d828De241282B9606C8e98Ea48526E2',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'LoopringCoin V2',
		symbol: 'LRC',
		decimals: 18,
		address: {
			1: '0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'EthLend Token',
		symbol: 'LEND',
		decimals: 18,
		address: {
			1: '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'LoomToken',
		symbol: 'LOOM',
		decimals: 18,
		address: {
			1: '0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Livepeer Token',
		symbol: 'LPT',
		decimals: 18,
		address: {
			1: '0x58b6A8A3302369DAEc383334672404Ee733aB239',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Liquidity.Network Token',
		symbol: 'LQD',
		decimals: 18,
		address: {
			1: '0xD29F0b5b3F50b07Fe9a9511F7d86F4f4bAc3f8c4',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Decentraland MANA',
		symbol: 'MANA',
		decimals: 18,
		address: {
			1: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Matic Token',
		symbol: 'MATIC',
		decimals: 18,
		address: {
			1: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Marblecoin',
		symbol: 'MBC',
		decimals: 18,
		address: {
			1: '0x8888889213DD4dA823EbDD1e235b09590633C150',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Magnolia Token',
		symbol: 'MGN',
		decimals: 18,
		address: {
			1: '0x80f222a749a2e18Eb7f676D371F19ad7EFEEe3b7',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Maker',
		symbol: 'MKR',
		decimals: 18,
		address: {
			1: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
			3: '0x0',
			4: '0x0',
			42: '0xAaF64BFCC32d0F15873a02163e7E500671a4ffcD'
		},
		max: '999'
	},
	{
		name: 'Melon Token',
		symbol: 'MLN',
		decimals: 18,
		address: {
			1: '0xec67005c4E498Ec7f55E092bd1d35cbC47C91892',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Modum Token',
		symbol: 'MOD',
		decimals: 0,
		address: {
			1: '0x957c30aB0426e0C93CD8241E2c60392d08c6aC8e',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Nexo',
		symbol: 'NEXO',
		decimals: 18,
		address: {
			1: '0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Numeraire',
		symbol: 'NMR',
		decimals: 18,
		address: {
			1: '0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Orchid',
		symbol: 'OXT',
		decimals: 18,
		address: {
			1: '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Panvala pan',
		symbol: 'PAN',
		decimals: 18,
		address: {
			1: '0xD56daC73A4d6766464b38ec6D91eB45Ce7457c44',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'PAX',
		symbol: 'PAX',
		decimals: 18,
		address: {
			1: '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Paxos Gold',
		symbol: 'PAXG',
		decimals: 18,
		address: {
			1: '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Pinakion',
		symbol: 'PNK',
		decimals: 18,
		address: {
			1: '0x93ED3FBe21207Ec2E8f2d3c3de6e058Cb73Bc04d',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'POA ERC20 on Foundation',
		symbol: 'POA20',
		decimals: 18,
		address: {
			1: '0x6758B7d441a9739b98552B373703d8d3d14f9e62',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'QChi',
		symbol: 'QCH',
		decimals: 18,
		address: {
			1: '0x687BfC3E73f6af55F0CccA8450114D107E781a0e',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Quantstamp Token',
		symbol: 'QSP',
		decimals: 18,
		address: {
			1: '0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Ripio Credit Network Token',
		symbol: 'RCN',
		decimals: 18,
		address: {
			1: '0xF970b8E36e23F7fC3FD752EeA86f8Be8D83375A6',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Raiden Token',
		symbol: 'RDN',
		decimals: 18,
		address: {
			1: '0x255Aa6DF07540Cb5d3d297f0D0D4D84cb52bc8e6',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name:
			'Real Token - Fullerton Ave property in Detroit USA - only whitelisted users',
		symbol: 'RTOKEN',
		decimals: 18,
		address: {
			1: '0x22c8ecf727c23422f47093b562ec53c139805301',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Republic Token',
		symbol: 'REN',
		decimals: 18,
		address: {
			1: '0x408e41876cCCDC0F92210600ef50372656052a38',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Reputation',
		symbol: 'REP',
		decimals: 18,
		address: {
			1: '0x1985365e9f78359a9B6AD760e32412f4a445E862',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Darwinia Network Native Token',
		symbol: 'RING',
		decimals: 18,
		address: {
			1: '0x9469D013805bFfB7D3DEBe5E7839237e535ec483',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'iEx.ec Network Token',
		symbol: 'RLC',
		decimals: 9,
		address: {
			1: '0x607F4C5BB672230e8672085532f7e901544a7375',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Rocket Pool',
		symbol: 'RPL',
		decimals: 18,
		address: {
			1: '0xB4EFd85c19999D84251304bDA99E90B92300Bd93',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Salt',
		symbol: 'SALT',
		decimals: 8,
		address: {
			1: '0x4156D3342D5c385a87D264F90653733592000581',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'SANtiment network token',
		symbol: 'SAN',
		decimals: 18,
		address: {
			1: '0x7C5A0CE9267ED19B22F8cae653F198e3E8daf098',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Synth sETH',
		symbol: 'sETH',
		decimals: 18,
		address: {
			1: '0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Shuffle.Monster V3',
		symbol: 'SHUF',
		decimals: 18,
		address: {
			1: '0x3A9FfF453d50D4Ac52A6890647b823379ba36B9E',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Status Network Token',
		symbol: 'SNT',
		decimals: 18,
		address: {
			1: '0x744d70FDBE2Ba4CF95131626614a1763DF805B9E',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Synthetix Network Token',
		symbol: 'SNX',
		decimals: 18,
		address: {
			1: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'SPANK',
		symbol: 'SPANK',
		decimals: 18,
		address: {
			1: '0x42d6622deCe394b54999Fbd73D108123806f6a18',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'StorjToken',
		symbol: 'STORJ',
		decimals: 8,
		address: {
			1: '0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Synth sUSD',
		symbol: 'sUSD',
		decimals: 18,
		address: {
			1: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	// {
	// 	name: 'Testnet DAI',
	// 	symbol: 'TDAI',
	// 	decimals: 18,
	// 	address: {
	// 		1: '0x6b175474e89094c44da98b954eedeac495271d0f',
	// 		3: '0x0',
	// 		4: '0x0',
	// 		42: '0x7d669a64deb8a4a51eea755bb0e19fd39ce25ae9'
	// 	},
	// 	max: '999'
	// },
	{
		name: 'TrueAUD',
		symbol: 'TAUD',
		decimals: 18,
		address: {
			1: '0x00006100F7090010005F1bd7aE6122c3C2CF0090',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'TrueCAD',
		symbol: 'TCAD',
		decimals: 18,
		address: {
			1: '0x00000100F2A2bd000715001920eB70D229700085',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'TrueGBP',
		symbol: 'TGBP',
		decimals: 18,
		address: {
			1: '0x00000000441378008EA67F4284A57932B1c000a5',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'TrueHKD',
		symbol: 'THKD',
		decimals: 18,
		address: {
			1: '0x0000852600CEB001E08e00bC008be620d60031F2',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Monolith TKN',
		symbol: 'TKN',
		decimals: 8,
		address: {
			1: '0xaAAf91D9b90dF800Df4F55c205fd6989c977E73a',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Trustcoin',
		symbol: 'TRST',
		decimals: 6,
		address: {
			1: '0xCb94be6f13A1182E4A4B6140cb7bf2025d28e41B',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'BiLira',
		symbol: 'TRYB',
		decimals: 6,
		address: {
			1: '0x2C537E5624e4af88A7ae4060C022609376C8D0EB',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'TrueUSD',
		symbol: 'TUSD',
		decimals: 18,
		address: {
			1: '0x0000000000085d4780B73119b644AE5ecd22b376',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'USD//C',
		symbol: 'USDC',
		decimals: 6,
		address: {
			1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'StableUSD',
		symbol: 'USDS',
		decimals: 6,
		address: {
			1: '0xA4Bdb11dc0a2bEC88d24A3aa1E6Bb17201112eBe',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Veritaseum',
		symbol: 'VERI',
		decimals: 18,
		address: {
			1: '0x8f3470A7388c05eE4e7AF3d01D8C722b0FF52374',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Wrapped BTC',
		symbol: 'WBTC',
		decimals: 8,
		address: {
			1: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Wrapped CryptoKitties',
		symbol: 'WCK',
		decimals: 18,
		address: {
			1: '0x09fE5f0236F0Ea5D930197DCE254d77B04128075',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'Wrapped Ether',
		symbol: 'WETH',
		decimals: 18,
		address: {
			1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
			3: '0x0',
			4: '0x0',
			42: '0xd0A1E359811322d97991E03f863a0C30C2cF029C'
		},
		max: '999'
	},
	{
		name: 'CryptoFranc',
		symbol: 'XCHF',
		decimals: 18,
		address: {
			1: '0xB4272071eCAdd69d933AdcD19cA99fe80664fc08',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: 'XIO Network',
		symbol: 'XIO',
		decimals: 18,
		address: {
			1: '0x0f7F961648aE6Db43C75663aC7E5414Eb79b5704',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	},
	{
		name: '0x Protocol Token',
		symbol: 'ZRX',
		decimals: 18,
		address: {
			1: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
			3: '0x0',
			4: '0x0',
			42: '0x0'
		},
		max: '999'
	}
];

export const UNISWAP_TOKEN_LIST_WITH_ETH = [{ ...ETH }, ...UNISWAP_TOKEN_LIST];

// #################################################### COLLECTION

export const TOKEN_LIST = [
	{
		name: RelevantInputData.kyberTokenList,
		data: [...KYBER_TOKEN_LIST]
	},
	{
		name: RelevantInputData.kyberTokenListWithEth,
		data: [...KYBER_TOKEN_LIST_WITH_ETH]
	},
	{
		name: RelevantInputData.uniswapTokenList,
		data: [...UNISWAP_TOKEN_LIST]
	},
	{
		name: RelevantInputData.uniswapTokenListWithEth,
		data: [...UNISWAP_TOKEN_LIST_WITH_ETH]
	},
	{
		name: RelevantInputData.fulcrumTokenList,
		data: [...FULCRUM_LEVERAGE_TOKEN_LIST]
	},
	{
		name: RelevantInputData.all,
		data: [
			...KYBER_TOKEN_LIST,
			...FULCRUM_LEVERAGE_TOKEN_LIST,
			...UNISWAP_TOKEN_LIST
		]
	},
	{
		name: RelevantInputData.allWithEth,
		data: [
			...KYBER_TOKEN_LIST_WITH_ETH,
			...FULCRUM_LEVERAGE_TOKEN_LIST,
			...UNISWAP_TOKEN_LIST
		]
	}
];
